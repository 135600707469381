<template>
  <b-card>
    <header-table title="جولات سياحية ذاتية" @create="onAdd" />
    <data-table ref="estatesTableee" :fields="fields" ep="/self-tours">
      <template #cell(actions)="row">
        <div class="d-flex">
          <b-button
            variant="flat-info"
            title="عرض المزيد من المعلومات"
            size="sm"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class=""
            v-b-tooltip.hover.v-info.top
            @click="row.toggleDetails"
            v-b-toggle.collapse-1
          >
            المزيد
            <feather-icon
              :icon="row.detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'"
            />
          </b-button>
          <actions-table @delete="onDelete(row.item)" @update="onUpdateClicked(row.item)">
            <template #extend-dropdown-item>
              <b-dropdown-item @click="openModalPoints(row.item)" >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">إضافة نقاط</span>
            </b-dropdown-item>
            </template>
          </actions-table>
        </div>
      </template>
      <template #row-details="row">
        <b-collapse id="collapse-1" class="mt-2">
          <component-tourist-tours :data="row" />
        </b-collapse>
      </template>
    </data-table>
    <form-modal
      ref="estateModalAboutContent"
      size="lg"
      :formSchema="estatesFormSchema"
      title="إضافة"
      v-model="activModel"
      :loading="loading"
      @confirm="onModalConfirmed"
    >
      <template #long>
        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >
          <b-row v-for="(item, index) in items" :id="item.id" :key="item.id" ref="row">
            <b-col cols="3">
              <form-input label="المنطقة" v-model="item.area" rules="required" />
            </b-col>

            <b-col cols="3">
              <form-input label="خط العرض" v-model="item.lat" rules="required" />
            </b-col>

            <b-col cols="3">
              <form-input label="خط الطول" v-model="item.lng" rules="required" />
            </b-col>

            <b-col cols="1" class="mb-50">
              <b-button
                @click="editalocation(index)"
                variant="primary"
                class="btn-icon outline-danger mt-2"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
            <b-col cols="1" class="mb-50">
              <b-button
                @click="removeItem(index)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-icon outline-danger mt-2"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-row>


        </b-form>
      </template>
      <template #image="{ form }">
        <form-file
          label="صورة"
          accept="image/*"
          v-model="image"
          id="form-file"
          name="image"
          placeholder="لم تقم باختيار صور"
          @change="previewImage"
        />
        <div class="text-center mb-1">
          <layout-input-image
            v-if="staticImages.url"
            :url="staticImages.url"
            :name="''"
            :is_last_imge="false"
            @deleteItem="deleteImage()"
          >
          </layout-input-image>
          <layout-input-image
            v-if="!staticImages.url && form.image"
            :url="form.image"
            :name="''"
            :is_last_imge="false"
            :is_delete="false"
          />
        </div>
      </template>
    </form-modal>

    <form-modal
      ref="estateModalPoints"
      size="lg"
      :formSchema="estatesFormSchemaPoints"
      title="إضافة"
      v-model="pointsModal"
      :loading="loading"
      @confirm="onModalConfirmedPoints"
    >
      <template #long>
        <b-form
          ref="form"
          :style="{ height: trHeight }"
          class="repeater-form"
          @submit.prevent="repeateAgain"
        >
          <b-row v-for="(item, index) in items" :id="item.id" :key="item.id" ref="row">
            <b-col cols="3">
              <form-input label="المنطقة" v-model="item.area" rules="required" />
            </b-col>

            <b-col cols="3">
              <form-input label="خط العرض" v-model="item.lat" rules="required" />
            </b-col>

            <b-col cols="3">
              <form-input label="خط الطول" v-model="item.lng" rules="required" />
            </b-col>

            <b-col cols="1" class="mb-50">
              <b-button
                @click="editalocation(index)"
                variant="primary"
                class="btn-icon outline-danger mt-2"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </b-col>
            <b-col cols="1" class="mb-50">
              <b-button
                @click="removeItem(index)"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-icon outline-danger mt-2"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr />
            </b-col>
          </b-row>

          <b-col cols="12">
            <b-button
              :display="false"
              class="w-100 outline"
              variant="outline-primary"
              @click="repeateAgain"
            >
              <feather-icon icon="MapIcon" class="mx-1" />
              <span>Add New Point </span>
            </b-button>
          </b-col>

          <b-modal
            v-model="mapModal"
            centered
            size="lg"
            title=""
            footer-class="removed"
            class="edit-modal"
          >
            <select-map
              @markerUpdated="onLocationSelect"
              :lat="markerPos.lat"
              :lng="markerPos.lng"
            >
            </select-map>
            <LoaderBtn variant="primary" @click="pushItems"></LoaderBtn>
          </b-modal>
        </b-form>
      </template>
    </form-modal>

  </b-card>
</template>
<script>
import {
  BForm,
  BCard,
  BImg,
  BRow,
  BCol,
  BModal,
  BButton,
  BCollapse,
  BDropdownItem
} from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/data-table/index.vue";
import FormModal from "@/components/FormModal.vue";
import HeaderTable from "@/components/data-table/components/header-table.vue";
import ActionsTable from "@/components/data-table/components/actions-table.vue";
import FormFile from "@/components/form-file/index.vue";
import dataSchema from "./mixin";
import LayoutInputImage from "@/components/LayoutInputImage.vue";
import FormInput from "@/components/form-input/index.vue";
import SelectMap from "@/components/select-map/index.vue";
import LoaderBtn from "@/components/loader-btn.vue";
import { heightTransition } from "@core/mixins/ui/transition";
import ComponentTouristTours from "@/modules/manage-center/components/ComponentTouristTours.vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BForm,
    LoaderBtn,
    SelectMap,
    FormInput,
    BImg,
    BRow,
    BCol,
    BButton,
    BModal,
    BCard,
    DataTable,
    FormModal,
    HeaderTable,
    ActionsTable,
    FormFile,
    LayoutInputImage,
    ComponentTouristTours,
    BDropdownItem
  },
  data() {
    return {
      markerPos: {
        lng: "37.16195747913507",
        lat: "36.19849488598441",
      },
      isEdit: false,
      index: null,
      mapModal: false,
      activModel: false,
      pointsModal: false,
      image: [],
      staticImages: { url: "" },
      items: [],
      nextTodoId: 2,
    };
  },
  mixins: [dataSchema, heightTransition],
  directives: {
    Ripple,
  },
  mounted() {
    this.initTrHeight();
  },
  created() {
    window.addEventListener("resize", this.initTrHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.initTrHeight);
  },
  methods: {
    ...mapActions("manageCenter/discoverAncientCity", ["update", "delete", "create","createPoint"]),

    repeateAgain() {
      this.mapModal = true;
    },

    editalocation(index) {
      this.mapModal = true;
      this.isEdit = true;
      this.index = index;
    },

    openModalPoints(form){
      this.pointsModal=true;
      this.items = form.points
      this.$refs.estateModalPoints.init({id:form.id})
    },

    pushItems() {
      this.mapModal = false;
      if (this.isEdit) {
        this.items[this.index].lng = this.markerPos.lng;
        this.items[this.index].lat = this.markerPos.lat;
        this.index = null;
        this.isEdit = false;
      } else {
        this.items.push({
          lng: this.markerPos.lng,
          lat: this.markerPos.lat,
        });
      }
    },

    onLocationSelect(data) {
      this.markerPos.lng = data.lng;
      this.markerPos.lat = data.lat;
    },
    updateMarker(x) {
      this.markerPos = x;
    },

    previewImage(e) {
      this.staticImages = {
        url: URL.createObjectURL(e.target.files[0]),
      };
    },
    deleteImage() {
      this.staticImages.url = "";
      this.image = null;
    },
    onAdd() {
      this.$refs.estateModalAboutContent.init({});
      this.items = [];
      this.deleteImage();
      this.activModel = true;
    },

    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف", {
          title: "تأكيد حذف",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.delete({ ep: `/self-tours/${item.id}` }).then(() => {
              this.$refs.estatesTableee.refreshTable();
            });
        });
    },

    onUpdateClicked(form) {
      this.deleteImage();
      this.$refs.estateModalAboutContent.init({
        ...form,
        tour_categories_ids: form.tourCategories.map((item) => item.id),
      });
      this.activModel = true;
    },


    onModalConfirmedPoints(form){
      this.createPoint({ data:{points: this.items} , ep: `/self-tours/add-points/${form.id}` }).then(() => {
          this.$refs.estatesTableee.refreshTable();
          this.pointsModal = false;
        });
    },

    onModalConfirmed(form) {
      var fd = new FormData();
      for (const key in form) {
        if (Object.hasOwnProperty.call(form, key)) {
          const element = form[key];
          if (key == "tour_categories_ids") {
            for (let i = 0; i <= element.length; i++) {
              if (element[i]) fd.append("tour_categories_ids[]", element[i]);
            }
          } else if (form[key] != null && form[key].length != 0 && key != "image") {
            if (key == "cost") fd.append(key, element.replace(/\D/g, ""));
            else fd.append(key, element);
          }
        }
      }

      if (this.image) {
        fd.append("image", this.image);
      }

      fd.append("points", JSON.stringify(this.items));

      console.log("points", this.items);

      if (form.id) {
        fd.append("_method", "PUT");
        this.update({ data: fd, ep: `/self-tours/${form.id}` }).then(() => {
          this.$refs.estatesTableee.refreshTable();
          this.activModel = false;
        });
      } else
        this.create({ data: fd, ep: `/self-tours` }).then(() => {
          this.$refs.estatesTableee.refreshTable();
          this.activModel = false;
        });
    },
  },
  computed: {
    ...mapGetters("manageCenter/discoverAncientCity", ["loading"]),
  },
};
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: 0.35s height;
}
</style>
